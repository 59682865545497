import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai'; // Importing the icon
import './ReadMoreButton.css';
import { Link } from 'react-router-dom';

function ReadMoreButton({ link, text = "Read More" }) {
  return (
    <div className="read-more-container">
      <Link to={link} className="read-more-link" data-replace={text}>
        <span>{text}</span>
        <AiOutlineArrowRight className="read-more-icon" />
      </Link>
    </div>
  );
}

export default ReadMoreButton;
