import React from 'react';
import '../About for home/About.css'
import AboutSection from './About';
import { Helmet } from 'react-helmet-async';


const AboutPage = () => {

  const containerVariants = {
    hidden: { opacity: 0, y: 150 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.7, staggerChildren: 0.2 } }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 150 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.9 } }
  };

  return (
    <div className="about-section py-5">
      <Helmet>
        <title>About Deus Primus</title>
      </Helmet>
     <AboutSection/>
    </div>
  );
};

export default AboutPage;
