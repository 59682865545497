import React from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai'; // Importing icon
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import './NotFound.css'; // Import your custom styles

function NotFound() {
  const navigate = useNavigate();

  // Function to handle back to home navigation
  const handleGoHome = () => {
    navigate('/'); // Navigate to homepage
  };

  return (
    <div className="notfound-container">
      <AiOutlineExclamationCircle className="notfound-icon" /> {/* Added Icon */}
      <h1 className="notfound-title">404</h1>
      <p className="notfound-message">
        Oops! The page you are looking for does not exist.
      </p>
      <button className="notfound-button" onClick={handleGoHome}>
        Go to Homepage
      </button>
    </div>
  );
}

export default NotFound;
