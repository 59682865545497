import React from 'react';
import Slider from 'react-slick';
import './Carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

// Custom left arrow
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="carousel-button carousel-prev" onClick={onClick}>
      <AiOutlineLeft size={24} /> {/* Adjust the size if necessary */}
    </div>
  );
}

// Custom right arrow
function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="carousel-button carousel-next" onClick={onClick}>
      <AiOutlineRight size={24} /> {/* Adjust the size if necessary */}
    </div>
  );
}

function Carousel({ item }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,  // For screens smaller than 768px
        settings: {
          slidesToShow: 1, // Show 1 item on smaller screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {item.map((c, index) => (
          <div key={index} className="carousel-item">
            <div className="carousel-image">
              <img src={c.image} alt="carousel" />
            </div>
            <div className="carousel-text">
              <h2>{c.title}</h2>
              <p>{c.content}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Carousel;
