import React from 'react';
import Eria from '../../assets/Deus/Eria.jpg';
import Musa from '../../assets/Deus/Misa.jpg';
import Rodgers from '../../assets/Deus/Rodgers.jpg';
import David from '../../assets/Deus/David.jpg';
import ismail from '../../assets/Deus/Ismail.jpg';
import Fransis from '../../assets/Deus/Fransis.jpg';
import Wilber from '../../assets/Deus/Wilber.jpg';
import Quraish from '../../assets/Deus/Quraish.jpg';
import Deo from '../../assets/Deus/Deo.jpg';
import Henry from '../../assets/Deus/Henry.jpg';


function FullTeam() {
  return (
    <>
      <div className="teamheader">
          <h1>Our Team</h1>
        </div>
        <div className='team'>

    <div className='teamitem'>
    <img
        src={Eria} 
        alt="Our Values"
        className="team-image"
    />
    <h1 className='role'>Company President</h1>
    <h1 className='teamname'>Dr Mujumbi Eria</h1>
    <p>Pharmacist</p>
    </div>
    
   
    </div>
    <div className='team'>

   
    <div className='teamitem'>
    <img
        src={Musa} 
        alt="Our Values"
        className="team-image"
    />
    <h1 className='role'>Secretary</h1>
    <h1 className='teamname'>Dr Mpanso Moses</h1>
    <p>Pharmacist, Procurement Specialist</p>
    </div>
    <div className='teamitem'>
       <img
         src={Henry} 
         alt="Our Values"
         className="team-image"
       />
       <h1 className='role'>Member</h1>
       <h1 className='teamname'>Dr Kibuuka Henry</h1>
       <p>Pharmacist, Procurement Expert, Epidemiologist & Biostatistician</p>
       </div>
   
       <div className='teamitem'>
    <img
        src={Rodgers} 
        alt="Our Values"
        className="team-image"
    />
    <h1 className='role'>Member</h1>
    <h1 className='teamname'>Dr Kabugo Rodgers Charles</h1>
    <p>Pharmacist, Public Health Specialist/Pharmaceutical Analyst (MPH, MPA) </p>
        </div>

   
    </div>
     <div className='team'>
       
     <div className='teamitem'>
       <img
         src={Deo} 
         alt="Our Values"
         className="team-image"
       />
       <h1 className='role'>Member</h1>
       <h1 className='teamname'>Dr Kivumbi Deogracious</h1>
       <p>Pharmacist</p>
       </div>
       <div className='teamitem'>
       <img
         src={David} 
         alt="Our Values"
         className="team-image"
       />
       <h1 className='role'>Member</h1>
       <h1 className='teamname'>Dr Tumusiime David</h1>
       <p>Pharmacist</p>
       </div>
       <div className='teamitem'>
       <img
         src={ismail} 
         alt="Our Values"
         className="team-image"
       />
       <h1 className='role'>Member</h1>
       <h1 className='teamname'>Dr Ssengonzi Ismail</h1>
       <p>Pharmacist</p>
       </div>
     </div>

     <div className='team'>
       
      
       <div className='teamitem'>
       <img
         src={Wilber} 
         alt="Our Values"
         className="team-image"
       />
       <h1 className='role'>Member</h1>
       <h1 className='teamname'>Dr Beera Wilberforce</h1>
       <p>Pharmacist</p>
       </div>
       <div className='teamitem'>
       <img
         src={Quraish} 
         alt="Our Values"
         className="team-image"
       />
       <h1 className='role'>Member</h1>
       <h1 className='teamname'>Dr Nantajja Quraish</h1>
       <p>Pharmacist</p>
       </div>

       <div className='teamitem'>
    <img
        src={Fransis} 
        alt="Our Values"
        className="team-image"
    />
    <h1 className='role'>Member</h1>
    <h1 className='teamname'>Dr Kyeyune Francis</h1>
    <p>Pharmacist</p>
    </div>
     </div>
     
    </>
  )
}

export default FullTeam