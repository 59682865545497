// src/Products.js
import React from 'react';
import Carousel from '../../Utils/Carousel/Carousel';
import "./Products.css";
import Zan from '../../assets/Deus/Zandrous.jpg';
import Ome from '../../assets/Deus/Omecod9.jpg';
import Fem from '../../assets/Deus/Femstal.jpg';


function Products() {
  const items = [
    {
      image: Ome,
      title:'OMECOD SYRUP',
      content: "Boosts children's health with vitamins A, D, and E.",
    },
    {
      image: Zan,
      title:'ZANDROS',
      content: 'A premium nutraceutical supplement.',
    },
    {
      image: Fem,
      title:'FEMSTAL FEMININE WASH',
      content: 'Designed for gentle feminine hygiene.',
    },
  ];

  return (
    <div className='products'>
      <h1 className='product-header'>Products</h1>
      <h4>Importers of Omecod Syrup, Zandros, and Femstal Feminine Wash</h4>
      <Carousel item={items} />
     
    </div>
   
  );
}

export default Products;
