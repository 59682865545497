import React from 'react';
import '../About for home/About.css'
import { motion } from 'framer-motion';
import { AiOutlineTeam,AiOutlineMedicineBox, AiOutlineAim, AiOutlineEye } from 'react-icons/ai';
import abtimg from '../../assets/about.jpg';
import vision from '../../assets/vision.jpg';
import FullTeam from './FullTeam';


const AboutSection = () => {

  const containerVariants = {
    hidden: { opacity: 0, y: 150 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.7, staggerChildren: 0.2 } }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 150 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.9 } }
  };

  return (

  <div className="about-section">
      {/* About Us Section */}
      <div >
        <div className="about-content">
          <img
            src={abtimg}
            alt="about deus primus"
            className="about-image"
          />
          <div className="about-text">
          <AiOutlineMedicineBox className="ouricon" />
          <h2 className="ourh2">
             About Deus Primus
          </h2>
            <p>
            Deus Primus is a prominent player in Uganda's pharmaceutical industry, 
          specializing in pharmaceutical importation and distribution. Led by a team of 10
          experienced pharmacists, we are dedicated to providing high-quality pharmaceutical 
          products to the Ugandan market. Our commitment to excellence, integrity, and 
          customer satisfaction drives us to continuously contribute to the improvement of 
          healthcare in Uganda.</p>
           
          </div>
        </div>
      </div>
      <div>
      
      <div className="about-content">
       
        <div className="about-text">
        <AiOutlineEye className="ouricon" /> 
        <h2 className="ourh2">Our Vision</h2>
          <p>
          The vision of Deus Primus Company is to be a leading provider of pharmaceutical 
          products in Uganda, known for its commitment to excellence, integrity, and customer satisfaction.
        </p>
        </div>
        <img
          src={vision}
          alt="Farming"
          className="about-image"
        />
      </div>
    </div>
      {/* Our Mission */}
      <motion.div 
        className="mission-vision-container"
        initial="hidden"
        whileInView="visible"
        variants={containerVariants}
        viewport={{ once: true }}
      >
        <div className="mission">
          
          <AiOutlineAim className="ouricon" />
          <motion.h2 className='ourh2' variants={itemVariants}>Our Mission</motion.h2>
         
          <motion.p variants={itemVariants}>
          The mission of the company is to improve the quality of healthcare in Uganda by 
            providing access to high-quality pharmaceutical products and services.
           </motion.p>
        </div>

      </motion.div>

      <motion.div 
        className="mission-vision-container"
        initial="hidden"
        whileInView="visible"
        variants={containerVariants}
        viewport={{ once: true }}
      >
     
     <FullTeam/>
    
      </motion.div>
    </div>

  );
};

export default AboutSection;
