import React from 'react';
import { AiOutlineMail, AiOutlinePhone, AiOutlineEnvironment, AiOutlineSend } from 'react-icons/ai';
import './Contact.css'; // Import external CSS for styling
import emailjs from 'emailjs-com';


const Contact = () => {

  // const sendContactForm = (e) => {
  //   e.preventDefault();
  
  //   emailjs.sendForm('service_ppukajq', 'template_tgqedke', e.target, 'EsNgctiGv9DtL0exT')
  //     .then((result) => {
  //         console.log("Email sent: ", result.text);
  //         alert("Your message has been sent!");
  //     }, (error) => {
  //         console.log("Failed to send email: ", error.text);
  //         alert("Failed to send message. Please try again.");
  //     });
  // };
  
  const sendContactForm = (e) => {
    e.preventDefault();
  
    emailjs.send('service_ppukajq', 'template_tgqedke', {
        user_name: e.target.user_name.value,
        user_email: e.target.user_email.value,
        user_phone: e.target.user_phone.value,
        message: e.target.message.value,
        from_name: e.target.user_name.value,   // Set the sender's name here
        from_email: e.target.user_email.value  // Set the sender's email here
      }, 'EsNgctiGv9DtL0exT')
      .then((result) => {
          console.log("Email sent:", result.text);
          alert("Your message has been sent!");
      }, (error) => {
          console.log("Failed to send email:", error.text);
          alert("Failed to send message. Please try again.");
      });
  };
  
  
  return (
    <div className="contact-container">
      <div className="contact-form-container">
        <div className="contact-header">
          <h3>Have Questions?</h3>
        </div>
        <h2 className='introch'>Send us a Message</h2>
        {/* <form className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" placeholder="Your Name" required />
          </div>
          <div className="form-group-inline">
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" placeholder="Your Email" required />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" placeholder="Your Phone" required />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea id="message" placeholder="Your Message" rows="5" required></textarea>
          </div>
          <button type="submit" className="contact-submit-btn">
            <AiOutlineSend /> Get In Touch
          </button>
        </form> */}

<form className="contact-form" onSubmit={sendContactForm}>
      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="user_name" placeholder="Your Name" required />
      </div>
      <div className="form-group-inline">
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="user_email" placeholder="Your Email" required />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone:</label>
          <input type="tel" id="phone" name="user_phone" placeholder="Your Phone" required />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" placeholder="Your Message" rows="5" required></textarea>
      </div>
      <button type="submit" className="contact-submit-btn">
        <AiOutlineSend /> Get In Touch
      </button>
    </form>
      </div>

      <div className="contact-details-container">
        <div className="contact-card">
          <div className="contact-card-icon">
            <AiOutlinePhone />
          </div>
          <div className="contact-card-content">
            <p className="contact-card-title">Hotline:</p>
            <p className="contact-card-info">
              <a href="tel:0783650107">0783650107</a>
            </p>
          </div>
        </div>
        <div className="contact-card">
          <div className="contact-card-icon">
            <AiOutlineMail />
          </div>
          <div className="contact-card-content">
            <p className="contact-card-title">Official Email:</p>
            <p className="contact-card-info">
              <a href="mailto:agriaidfoundationlimited@gmail.com">deusprimus11@gmail.com</a>
            </p>
          </div>
        </div>
        <div className="contact-card">
          <div className="contact-card-icon">
            <AiOutlineEnvironment />
          </div>
          <div className="contact-card-content">
            <p className="contact-card-title">Address:</p>
            <p className="contact-card-info">
              <a href="https://www.google.com/maps/search/?api=1&query=Kanyanya,+shell,+Kampala,+Uganda" target="_blank" rel="noopener noreferrer">
                Kanyanya Next to Shell, Kampala, Uganda
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
