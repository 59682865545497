import React from 'react'
import Products from '../Products/Products'
import { Helmet } from 'react-helmet-async'
import AllProducts from '../Products/AllProducts'


function WhatWeDoPage() {
  return (
    <div>
       <Helmet>
        <title>What We Do | Deus Primus</title>
      </Helmet>
   <AllProducts/>
   
   </div>
  )
}

export default WhatWeDoPage