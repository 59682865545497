import React from 'react'
import './AllProducts.css'
import tbalm from '../../assets/Deus/Tbalm.jpg'
import tnasal from '../../assets/Deus/Tnasal.jpg'
import Zan from '../../assets/Deus/Zandrous.jpg';
import Ome from '../../assets/Deus/Omecod9.jpg';
import Fem from '../../assets/Deus/Femstal.jpg';

function AllProducts() {
  return (
    <>
     <div className='team'>
       
       <div className='teamitem'>
       <img
         src={Fem} 
         alt="Our Values"
         className="team-image"
       />
       
       <p className='product-name'>FEMSTAL FEMININE WASH</p>
       </div>
       <div className='teamitem'>
       <img
         src={Ome} 
         alt="Our Values"
         className="team-image"
       />
      
       <p className='product-name'>OMECOD SYRUP</p>
       </div>
       <div className='teamitem'>
       <img
         src={Zan} 
         alt="Our Values"
         className="team-image"
       />
      
       <p className='product-name'>ZANDROS</p>
       </div>
     </div>

     <div className='team'>
       
       <div className='teamitem'>
       <img
         src={tbalm} 
         alt="Our Values"
         className="team-image"
       />
       
       <p className='product-name'>T BALM PAIN RUB</p>
       </div>
       <div className='teamitem'>
       <img
         src={tnasal} 
         alt="Our Values"
         className="team-image"
       />
      
       <p className='product-name'>T NASAL INHALER</p>
       </div>
       {/* <div className='teamitem'>
       <img
         src={Zan} 
         alt="Our Values"
         className="team-image"
       />
      
       <p className='product-name'>Dr Nantajja Quraish</p>
       </div> */}
     </div>
    </>
  )
}

export default AllProducts