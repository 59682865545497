// import React from 'react';
// import { HelmetProvider } from 'react-helmet-async';
// import './App.css';
// import { BrowserRouter as Router, Routes, Route,useRoutes } from 'react-router-dom';
// import Home from './Components/Home/Home'; // Home component
// import AboutPage from './Components/About/AboutPage'; // Import additional components as pages
// import 'bootstrap/dist/css/bootstrap.min.css';
// import WhatWeDoPage from './Components/What we do/WhatWeDoPage';
// import ContactPage from './Components/Contact/ContactPage';
// import NavBar from './Utils/NavBar';
// import NotFound from './Components/Not found/Notfound';
// import Footer from './Components/Footer/Footer';
// import ScrollToTop from './Utils/ScrollToTop';

// function App() {
//   const routesArray = [
//     {
//       path: "/",
//       element: <Home />,
//     },
//     {
//       path: "/home",
//       element: <Home />,
//     },
//     {
//       path: "/about-us/",
//       element: <AboutPage/>,
//     },
//     {
//       path: "/what-we-do/",
//       element: <WhatWeDoPage/>,
//     },
//     {
//       path: "/contact-us/",
//       element: <ContactPage/>,
//     },
   
//     // Add other routes as needed
//     {
//       path: "*",
//       element: <NotFound />,
//     },
//   ];

//   let routesElement = useRoutes(routesArray);

//   return (
//     <HelmetProvider>
//     {/* <Router>
//       <ScrollToTop/>
//       <NavBar />
//       <Routes>
//         <Route path="/" element={<Home/>} /> 
//         <Route path="/about-us" element={<AboutPage />} /> 
//         <Route path="/what-we-do" element={<WhatWeDoPage />} />
//         <Route path="/contact" element={<ContactPage/>} /> 
//         <Route path="*" element={<NotFound/>} />
//         </Routes>
        
//         <Footer/>
//     </Router> */}
//     <ScrollToTop/>
//     <NavBar/>
//     <div>{routesElement}</div>
//     <Footer/>
//     </HelmetProvider>
//   );
// }

// export default App;


import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import Home from './Components/Home/Home'; // Home component
import AboutPage from './Components/About/AboutPage'; // Import additional components as pages
import 'bootstrap/dist/css/bootstrap.min.css';
import WhatWeDoPage from './Components/What we do/WhatWeDoPage';
import ContactPage from './Components/Contact/ContactPage';
import NavBar from './Utils/NavBar';
import NotFound from './Components/Not found/Notfound';
import Footer from './Components/Footer/Footer';
import ScrollToTop from './Utils/ScrollToTop';

function AppRoutes() {
  const routesArray = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/about-us/",
      element: <AboutPage />,
    },
    {
      path: "/what-we-do/",
      element: <WhatWeDoPage />,
    },
    {
      path: "/contact-us/",
      element: <ContactPage />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routesArray);
}

function App() {
  return (
    <HelmetProvider>
      <Router> {/* Router wrapping AppRoutes */}
        <ScrollToTop />
        <NavBar />
        <AppRoutes /> {/* Use the custom AppRoutes component to define routes */}
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
