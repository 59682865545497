import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillFacebook, AiFillTwitterSquare, AiFillInstagram } from 'react-icons/ai';
import './Footer.css'; // Import custom CSS for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footercontainer">
        <div className="row">
          {/* About Section */}
          <div className="col-md-4 footer-about">
            <h4>Deus Primus Limited</h4>
            <p>
              We are committed to improving health by providing quality pharmaceutical and nutraceutical products.
            </p>
          </div>

          {/* Navigation Links */}
          <div className="col-md-4 footer-links">
            <h4>Quick Links</h4>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About</Link>
              </li>
              <li>
                <Link to="/what-we-do">What We Do</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact</Link>
              </li>
            </ul>
          </div>

          {/* Social Media Links */}
          <div className="col-md-4 footer-social">
            <h4>Follow Us</h4>
            <div className="social-icons">
              <Link to="https://facebook.com" target="_blank">
                <AiFillFacebook size={30} />
              </Link>
              <Link to="https://twitter.com" target="_blank">
                <AiFillTwitterSquare size={30} />
              </Link>
              <Link to="https://instagram.com" target="_blank">
                <AiFillInstagram size={30} />
              </Link>
            </div>
          </div>
        </div>

        {/* Copyright and Powered by Section */}
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} Deus Primus Limited. All Rights Reserved.</p>
          <p className="powered-by">
            Powered by <Link to="https://contecfy.com">Contecfy</Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;


// import React from 'react';
// import { Link } from 'react-router-dom';
// import './Footer.css'; // Import CSS for styling

// const Footer = () => {
//   return (
//     <footer className="footer">
//       <div className="container">
//         <div className="footer-sections">
//           <div className="footer-column">
//             <h4>Company</h4>
//             <ul>
//               <li><Link to="/about">About Us</Link></li>
//               <li><Link to="/careers">Careers</Link></li>
//               <li><Link to="/press">Press</Link></li>
//               <li><Link to="/testimonials">Testimonials</Link></li>
//             </ul>
//           </div>
//           <div className="footer-column">
//             <h4>Products</h4>
//             <ul>
//               <li><Link to="/deus-primus-health">Deus Primus Health</Link></li>
//               <li><Link to="/deus-primus">Deus Primus</Link></li>
//               <li><Link to="/quick-save">Quick Save</Link></li>
//             </ul>
//           </div>
//           <div className="footer-column">
//             <h4>Services</h4>
//             <ul>
//               <li><Link to="/home-delivery">Home Delivery</Link></li>
//               <li><Link to="/online-prescriptions">Online Prescriptions</Link></li>
//               <li><Link to="/pharmacy-finder">Pharmacy Finder</Link></li>
//               <li><Link to="/price-match-guarantee">Price Match Guarantee</Link></li>
//             </ul>
//           </div>
//           <div className="footer-column">
//             <h4>Resources</h4>
//             <ul>
//               <li><Link to="/for-doctors">For Doctors</Link></li>
//               <li><Link to="/for-pharmacists">For Pharmacists</Link></li>
//               <li><Link to="/medication-directory">Medication Directory</Link></li>
//               <li><Link to="/preferred-drug-list">Preferred Drug List</Link></li>
//               <li><Link to="/common-drugs">Common Drugs</Link></li>
//             </ul>
//           </div>
//         </div>
//         <div className="footer-bottom">
//           <p>Powered by <strong>Contectfy</strong></p>
//           <p>© {new Date().getFullYear()} Deus Primus Limited. All rights reserved.</p>
//           <div className="footer-policies">
//             <Link to="/privacy-policy">Privacy Policy</Link>
//             <span>|</span>
//             <Link to="/terms-and-conditions">Terms & Conditions</Link>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;
