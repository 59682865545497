// BackToTop.js
import React, { useState, useEffect } from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai'; // Import the arrow up icon
import './Backtotop.css'; // Import CSS for styling

const BackToTop = () => {
    const [visible, setVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <button 
            className={`back-to-top ${visible ? 'visible' : ''}`} 
            onClick={handleClick}
            aria-label="Back to top"
        >
            <AiOutlineArrowUp size={24} /> {/* Render the icon */}
        </button>
    );
};

export default BackToTop;
