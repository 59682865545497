import React from 'react';
import Contact from './Contact';
import { Helmet } from 'react-helmet-async';

function ContactPage() {
  return (
    <div className='contact-page'>
       <Helmet>
        <title>Contact Deus Primus</title>
      </Helmet>
     <div className='contact-header'>
      <h1>Contact Deus Primus</h1>
     </div>
    <Contact/>
    </div>
  )
}

export default ContactPage