// import { useState } from 'react';
// import { Link } from 'react-router-dom'; // For navigation
// import Offcanvas from 'react-bootstrap/Offcanvas';
// import { AiOutlineMenu, AiFillFacebook, AiFillTwitterSquare, AiFillInstagram } from 'react-icons/ai'; // Icons
// import './SideNav.css'; // Import CSS for styling

// function SideNav() {
//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   return (
//     <>
//       {/* Hamburger Icon */}
//       <AiOutlineMenu className="hamburger-icon" size={30} onClick={handleShow} />

//       <Offcanvas show={show} onHide={handleClose} placement="end">
//         <Offcanvas.Header closeButton className='closebtn'>
         
//         </Offcanvas.Header>
//         <Offcanvas.Body>
//           <nav className="mobile-nav">
//             <ul className="mobile-nav-links">
//               <li className="nav-item">
//                 <Link to="/" onClick={handleClose} className="link">
//                   Home
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <Link to="/about" onClick={handleClose} className="link">
//                   About
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <Link to="/services" onClick={handleClose} className="link">
//                   Services
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <Link to="/contact" onClick={handleClose} className="link">
//                   Contact
//                 </Link>
//               </li>
//             </ul>
//           </nav>

//           <div className="social-icons mt-4">
//             <h5>Follow Us</h5>
//             <div className="icons">
//               <Link to="https://facebook.com" target="_blank">
//                 <AiFillFacebook size={30} />
//               </Link>
//               <Link to="https://twitter.com" target="_blank">
//                 <AiFillTwitterSquare size={30} />
//               </Link>
//               <Link to="https://instagram.com" target="_blank">
//                 <AiFillInstagram size={30} />
//               </Link>
//             </div>
//           </div>
//         </Offcanvas.Body>
//       </Offcanvas>
//     </>
//   );
// }

// export default SideNav;

import { useState } from 'react';
import { Link } from 'react-router-dom'; // For navigation
import Offcanvas from 'react-bootstrap/Offcanvas';
import { AiOutlineMenu, AiFillFacebook, AiFillTwitterSquare, AiFillInstagram } from 'react-icons/ai'; // Icons
import { motion } from 'framer-motion'; // Import Framer Motion
import './SideNav.css'; // Import CSS for styling

function SideNav() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Define animation variants for links
  const variants = {
    hidden: { opacity: 0, y: -60 }, // Start off hidden and above
    visible: { opacity: 1, y: 0, transition: { duration: 0.9, ease: "easeInOut" } }, // Slide down with fade-in
  };

  return (
    <>
      {/* Hamburger Icon */}
      <AiOutlineMenu className="hamburger-icon" size={30} onClick={handleShow} />

      <Offcanvas show={show} onHide={handleClose} placement="end" className="wholenav">
        <Offcanvas.Header closeButton className="closebtn">
        </Offcanvas.Header>
        <Offcanvas.Body>
          <nav className="mobile-nav">
            <motion.ul
              className="mobile-nav-links"
              initial="hidden" // Initial animation state
              animate="visible" // Animate when shown
              variants={variants} // Apply the variants defined
            >
              <motion.li className="nav-item" variants={variants}>
                <Link to="/" onClick={handleClose} className="link">
                  Home
                </Link>
              </motion.li>
              <motion.li className="nav-item" variants={variants}>
                <Link to="/about-us" onClick={handleClose} className="link">
                  About
                </Link>
              </motion.li>
              <motion.li className="nav-item" variants={variants}>
                <Link to="/what-we-do" onClick={handleClose} className="link">
                  What We Do
                </Link>
              </motion.li>
              <motion.li className="nav-item" variants={variants}>
                <Link to="/contact-us" onClick={handleClose} className="link">
                  Contact
                </Link>
              </motion.li>
            </motion.ul>
          </nav>

          <div className="social-icons mt-4">
            <h5>Follow Us</h5>
            <div className="icons">
              <Link to="https://facebook.com" target="_blank" className='eachicon'>
                <AiFillFacebook size={30} />
              </Link>
              <Link to="https://twitter.com" target="_blank"className='eachicon'>
                <AiFillTwitterSquare size={30} />
              </Link>
              <Link to="https://instagram.com" target="_blank"className='eachicon'>
                <AiFillInstagram size={30} />
              </Link>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SideNav;
