import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Importing Link for navigation
import "./NavBar.css"; // Importing external CSS for styling
import SideNav from "./Side"; // Assuming this is for mobile nav
import Logo from "../assets/logo.jpg"; // Path to the logo
import { AiOutlineHeart, AiOutlineFund, AiOutlineGift } from "react-icons/ai";

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`Header ${isScrolled ? "scrolled" : ""}`}>
     
      <nav className="navbar">
        <div className="navbar-logo">
          <Link to={'/'}>
          <img src={Logo} alt="Logo" className="logo" />
          </Link>
        </div>
       
        <div className="alllinks">
          <ul className="navbar-items">
            <li className="navbar-item">
              <Link className="link-flash" to="/">Home</Link>
            </li>
            <li className="navbar-item">
              <Link className="link-flash" to="/about-us">About</Link>
            </li>
            <li className="navbar-item">
              <Link className="link-flash" to="/what-we-do">What We Do</Link>
            </li>
            <li className="navbar-item">
              <Link className="link-flash" to="/home">Blog</Link>
            </li>
            {/* <li className="navbar-item">
              <Link className="link-flash" to="/contact">Contact</Link>
            </li> */}
          </ul>
       
        </div>
        <div className="btndiv">
          <Link to="./contact-us" className="contactlink">
            <h1 className="contactbtn">
              Contact us
              </h1>
              </Link>
          </div>
        <div className="mobile-icon">
          <SideNav /> {/* This is for mobile navigation */}
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
