import React from 'react'
import Eria from '../../assets/Deus/Eria.jpg';
import Musa from '../../assets/Deus/Misa.jpg';
import Rodgers from '../../assets/Deus/Rodgers.jpg';

function Team() {
  return (
    <>
      <div className="teamheader">
          <h1>Our Team</h1>
        </div>
          <div className='team'>
       
            <div className='teamitem'>
            <img
              src={Eria} 
              alt="Our Values"
              className="team-image"
            />
            <h1 className='role'>Company President</h1>
            <h1 className='teamname'>Dr Mujumbi Eria</h1>
            <p>Pharmacist</p>
            </div>
            <div className='teamitem'>
            <img
              src={Musa} 
              alt="Our Values"
              className="team-image"
            />
            <h1 className='role'>Secretary</h1>
            <h1 className='teamname'>Dr Mpanso Moses</h1>
            <p>Pharmacist, Procurement Specialist</p>
            </div>
            <div className='teamitem'>
            <img
              src={Rodgers} 
              alt="Our Values"
              className="team-image"
            />
            <h1 className='role'>Member</h1>
            <h1 className='teamname'>Dr Kabugo Rodgers Charles</h1>
            <p>Pharmacist, Public Health Specialist/Pharmaceutical Analyst (MPH, MPA) </p>
            </div>
          </div>
    </>
  )
}

export default Team