import React from 'react'
import HeroSection from '../Hero/Hero'
import About from '../About for home/About'
import Contact from '../Contact/Contact';
import BackToTop from '../../Utils/backtotop/Backtotop';
import Products from '../Products/Products';
import { Helmet } from 'react-helmet-async';
import ReadMoreButton from '../../Utils/Readmore/Readmore';


function Home() {
  return (
    <>
     <Helmet>
        <title>Home | Deus Primus</title>
      </Helmet>
    <HeroSection/>
    
    <About/>
    <Products/>
    <div className='actiondiv'>
      <ReadMoreButton link="/what-we-do" text="See More " />
    </div>
    <BackToTop/>
    <Contact/>
    </>
  )
}

export default Home