import React from 'react';
import { motion } from 'framer-motion'; // Importing framer-motion for animations
import { AiOutlineArrowDown } from 'react-icons/ai'; // Import scroll down icon
import './HeroSection.css'; // Import CSS for styling
import heroImage from '../../assets/hero.png';

// Animation Variants
const textVariant = {
  hidden: { opacity: 0, y: -50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 1, ease: "easeInOut" }
  }
};

const imageVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 1, ease: "easeInOut" }
  }
};

const scrollIconVariant = {
  hidden: { opacity: 0, y: -10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.2,
      duration: 1,
      ease: "easeInOut",
      repeat: Infinity, // Keeps the animation repeating
      repeatType: "reverse"
    }
  }
};

const HeroSection = () => {
  // Function to handle scroll
  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight, // Scrolls to the next section
      behavior: 'smooth',
    });
  };

  return (
    <div className="hero-section d-flex align-items-center">
      <div className="container d-flex justify-content-between">
        {/* Text content */}
        <motion.div
          className="hero-text"
          variants={textVariant}
          initial="hidden"
          animate="visible"
        >
          <h1 className="hero-title">Your Health, Our Priority</h1>
          <p className="hero-subtitle">
            Delivering Quality Medical Products.
          </p>
        </motion.div>

        {/* Image */}
        <motion.div
          className="hero-image"
          variants={imageVariant}
          initial="hidden"
          animate="visible"
        >
          <img src={heroImage} alt="Healthcare" className="img-fluid" />
        </motion.div>
      </div>

      {/* Scroll Down Icon */}
      <motion.div
        className="scroll-down-indicator"
        onClick={handleScroll}
        variants={scrollIconVariant}
        initial="hidden"
        animate="visible"
      >
        <AiOutlineArrowDown className="scroll-icon" />
      </motion.div>
    </div>
  );
};

export default HeroSection;
